//约看
<template>
  <div class="personage">
    <personage></personage>
    <div class="personage_main b-c-f p-l-20 p-r-20" v-if="userInfo.position != 5">
      <div class="personage_head">
        <div class="personage_head_top">
          <div class="personage_head_txt">类型:</div>
          <ul class="personage_head_ul">
            <li
            @click="changeType('house_type',item.value)"
              :class="{ active: ruleForm.house_type == item.value }"
              v-for="(item, index) in getZuHousingTypeList()"
              :key="index"
            >{{ item.label }}</li>
          </ul>
        </div>
        <!-- <div class="personage_head_top">
          <div class="personage_head_txt">租售类型:</div>
          <ul class="personage_head_ul">
            <li :class="{ active: index == type }" v-for="(item, index) in typeList" :key="index">{{ item.name }}</li>
          </ul>
        </div> -->
      </div>
      <ul class="personage_ul">
        <li @click="hrefDetail(item)" v-for="(item, index) in list" :key="index">
          <div class="personage_ul_img m-r-20">
            <img
              :src="item.fang_fengmian_image"
              alt=""
            />
            <div class="personage_img_marking">{{ item.fang_level }}</div>
          </div>
          <div class="personage_ul_main f-1">
            <div class="personage_ul_name">{{ item.house_name }}</div>
            <div class="personage_ul_title">
              {{ item.fang_xing }} | {{ item.fang_mianji }}m² | {{ item.dian_number }}次 | {{ item.name }}
            </div>
            <div class="personage_ul_price">
              <div class="personage_ul_price_left m-r-20">
                <span>{{ item.can_price }}元/月</span>
              </div>
            </div>
            <div class="personage_ul_footer flex">
              <div class="personage_ul_footer_head flexs">
                <div class="personage_ul_footer_img">
                  <img :src="item.image" alt="" />
                </div>
                <div class="personage_ul_footer_main f-1 flex">
                  <div class="personage_ul_footer_name">{{ item.name }}</div>
                  <div class="personage_ul_footer_time flexs">
                    <span>看房时间：{{ item.createtime }}</span>
                    <span>入住时间：{{ item.fang_zhu_time }}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="personage_ul_footer_phone">
                <img src="../../assets/image/personage/icon21.png" alt="" />
              </div> -->
            </div>
          </div>
        </li>
      </ul>
      <div class="paging" v-if="total > 0">
        <el-pagination
          background
          @current-change="changePage"
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import personage from "@/components/Personage.vue";
export default {
  components: { personage },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      total: 0, //条数
      list: [], //列表
      ruleForm:{
        page:1,
        house_type:2,//1=新房2=住宅3=公寓4=写字楼5=商铺6=别墅
        type:2,//房源类型：1=出售2=出租
      }
    };
  },
  methods: {
    changePage (e) {
      this.ruleForm.page = e
      this.getHouseYueLookLog()
    },
    //去房源详情
    hrefDetail (item) {
      this.$router.push({ path: "/housingDetails",query:{id:item.house_id,type:2} });
    },
    //切换类型
    changeType (type,value) {
      this.ruleForm.page = 1
      this.ruleForm[type] = value
      this.getHouseYueLookLog()
    },
    getHouseYueLookLog() {
      this.$axios.houseYueLookLog(this.ruleForm).then(res=>{
        this.list = res.data.data
        this.total = res.data.total
      })
    },
  },
  created() {
    if ((this.userInfo.position === 3 || this.userInfo.position === 4) && this.userInfo.fang_type_privilege === 1) this.ruleForm.house_type = 4
    this.getHouseYueLookLog();
  }
};
</script>

<style lang="less" scoped>
.personage_head {
  padding: 30px 50px;
  .personage_head_top {
    display: flex;
    &:last-child {
      margin-top: 15px;
    }
    .personage_head_txt {
      margin-right: 30px;
      width: 70px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    .personage_head_ul {
      display: flex;
      flex-wrap: wrap;
      li {
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        color: #3273f6;
        font-weight: bold;
      }
    }
  }
}
.personage_ul {
  li {
    padding: 30px;
    display: flex;
    cursor: pointer;
    border-top: 1px solid #ebebeb;
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: flex-end;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: flex-end;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
